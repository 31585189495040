import Skeleton from '@components/skeleton/Skeleton';
import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';
import { TbChevronRight } from 'react-icons/tb';

const NumberBlock = ({
  title,
  data,
  href,
  loading,
  children,
  className,
}: {
  title: React.ReactNode;
  loading?: boolean;
  href?: string;
  data: {
    number?: number;
    name?: string;
    light?: boolean;
    suffix?: string;
    decimalPlaces?: number;
    href?: string;
  }[];
  children?: React.ReactNode;
  className?: string;
}) => {
  const header = (
    <div className="flex items-center gap-4 text-primary/80 w-full">
      <h4 className="flex-1 text-lg font-bold">{title}</h4>
      {href ? <TbChevronRight size={26} className="mt-1" /> : null}
    </div>
  );

  const inner = (
    <div
      className={classNames(
        'grow rounded-md bg-primary p-4 px-6 md:drop-shadow-sm',
        className,
      )}
    >
      {href ? (
        <Link href={href} className="flex items-center hover:opacity-80">
          {header}
        </Link>
      ) : (
        header
      )}
      <div className="mt-2 flex gap-6 uppercase">
        {data.map((d) => {
          const innerData = (
            <div key={d.name}>
              {!loading ? (
                <p
                  className={`text-5xl font-bold ${
                    d.light && 'text-secondary'
                  }`}
                >
                  {d.decimalPlaces != null
                    ? d.number?.toFixed(d.decimalPlaces)
                    : d.number}
                  {d.suffix != null ? (
                    <span className="text-3xl">{d.suffix}</span>
                  ) : null}
                </p>
              ) : (
                <Skeleton width={60} height={40} duration={0.8} />
              )}
              <p className="mt-4 text-sm text-secondary">{d.name}</p>
            </div>
          );

          const url = d.href ?? href ?? undefined;

          return url ? (
            <Link key={d.name} href={url} className="flex hover:opacity-80">
              {innerData}
            </Link>
          ) : (
            innerData
          );
        })}
      </div>
      {children}
    </div>
  );

  return inner;
};

export default NumberBlock;
