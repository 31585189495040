import classNames from 'classnames';
import { ToggleButton } from './ToggleButton';

type Props<T> = {
  onClick: (value: T) => void;
  buttons: { label: string; value: T }[];
  value: T;
  className?: string;
};

export const ToggleButtons = <T extends string | boolean | number>({
  onClick,
  buttons,
  value,
  className,
}: Props<T>) => {
  return (
    <div className="flex grow">
      {buttons.map((button, index) => (
        <ToggleButton
          className={classNames('grow w-0', className)}
          key={button.label}
          position={
            index === 0
              ? 'left'
              : index === buttons.length - 1
                ? 'right'
                : 'middle'
          }
          checked={value === button.value}
          onClick={() => onClick(button.value)}
          tall={false}
        >
          <div className="px-4 py-2">{button.label}</div>
        </ToggleButton>
      ))}
    </div>
  );
};
