import { DashboardDriverFragment } from '@/generated/graphql';
import classNames from 'classnames';
import { useId } from 'react';

export enum FilterOptions {
  PACKS_TO_UNLOAD = 'packs-to-unload',
  NATIONWIDE = 'nationwide',
  NOT_PICKED = 'not-picked',
  NOT_STARTED = 'not-started',
  NOT_FINISHED = 'not-finished',
}

const LABELS: Record<FilterOptions, string> = {
  [FilterOptions.PACKS_TO_UNLOAD]: 'Packs to Unload',
  [FilterOptions.NATIONWIDE]: 'Nationwide',
  [FilterOptions.NOT_PICKED]: 'Not Picked',
  [FilterOptions.NOT_STARTED]: 'Not Started',
  [FilterOptions.NOT_FINISHED]: 'Not Finished',
};

type Props = {
  value: FilterOptions | null;
  onChange: (value: FilterOptions | null) => void;
};

export const Filters = ({ value, onChange }: Props) => {
  const name = useId();

  return (
    <fieldset className="flex gap-2">
      <legend className="sr-only">Filter driver list</legend>
      {Object.values(FilterOptions).map((option) => (
        <div key={option}>
          <input
            name={name}
            type="radio"
            id={`${name}-${option}`}
            onClick={() => onChange(option === value ? null : option)}
            className="sr-only"
          />
          <label
            htmlFor={`${name}-${option}`}
            className={classNames(
              'cursor-pointer block font-medium leading-8 bg-primary border text-neutral border-primary transition-colors duration-300 ease-in-out hover:bg-secondary text-sm h-8 rounded px-2.5',
              { 'bg-secondary opacity-60': value === option },
            )}
          >
            {LABELS[option]}
          </label>
        </div>
      ))}
    </fieldset>
  );
};

export const filterDriver = (
  d: DashboardDriverFragment,
  selectedFilter: FilterOptions | null,
): boolean => {
  if (!selectedFilter) return true;

  switch (selectedFilter) {
    case FilterOptions.NATIONWIDE:
      return d.externalPacksToUnload.length > 0;
    case FilterOptions.PACKS_TO_UNLOAD:
      return d.packsToUnload.length > 0 || d.externalPacksToUnload.length > 0;
    case FilterOptions.NOT_PICKED:
      return !!d.packsToPick.length && !d.routeStartedAt;
    case FilterOptions.NOT_STARTED:
      return !d.routeStartedAt;
    case FilterOptions.NOT_FINISHED:
      return !d.routeEndedAt;
  }
};
