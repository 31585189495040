import { TZ } from '@/utilities/timezone';
import Skeleton from '@components/skeleton/Skeleton';
import { formatIso8601ToTime } from '@packfleet/datetime';
import React, { useMemo, useState } from 'react';
import { DashboardDriverFragment } from '../../generated/graphql';
import { pluralize } from '../../utilities/string';
import { DriverRow } from './DriverRow';
import { FilterOptions, Filters, filterDriver } from './Filters';

export type DriverWave = {
  shiftStartTime: string | null | undefined;
  drivers: DashboardDriverFragment[];
};

export type Props = {
  driverWaves: DriverWave[];
  loading: boolean;
  children?: React.ReactNode;
};

export const DashboardDrivers = ({ driverWaves, loading }: Props) => {
  const [selectedFilter, setSelectedFilter] = useState<FilterOptions | null>(
    null,
  );

  const driversWithStops = useMemo(() => {
    return driverWaves
      .map(({ drivers, ...rest }) => ({
        drivers: drivers.filter((d) => d.stopsTotal > 0),
        ...rest,
      }))
      .filter(({ drivers }) => drivers.length > 0);
  }, [driverWaves]);

  const filteredDriverWaves = useMemo(() => {
    return driversWithStops
      .map(({ drivers, ...rest }) => ({
        drivers: drivers.filter((d) => filterDriver(d, selectedFilter)),
        ...rest,
      }))
      .filter(({ drivers }) => drivers.length > 0);
  }, [driversWithStops, selectedFilter]);

  const totalDrivers = useMemo(
    () =>
      driversWithStops.reduce((acc, { drivers }) => acc + drivers.length, 0),
    [driversWithStops],
  );

  const filteredDrivers = useMemo(
    () =>
      filteredDriverWaves.reduce((acc, { drivers }) => acc + drivers.length, 0),
    [filteredDriverWaves],
  );

  return (
    <div>
      {loading ? (
        <div className="mb-8 flex flex-col gap-8">
          {new Array(6).fill(0).map((_, i) => (
            <div className="flex items-center gap-4" key={`skeleton${i}`}>
              <Skeleton circle width={30} height={30} duration={0.8} />
              <div className="flex-1">
                <Skeleton className="mt-3 w-full" height={10} duration={0.8} />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col gap-2 py-2">
          <h4 className="text-2xl font-bold min-w-[200px]">
            {selectedFilter ? `${filteredDrivers}/` : null}
            {totalDrivers === 0 && !selectedFilter ? 'No' : totalDrivers}{' '}
            {pluralize(totalDrivers, 'Driver', 'Drivers')}
          </h4>

          <Filters onChange={setSelectedFilter} value={selectedFilter} />
        </div>
      )}

      {filteredDriverWaves.map(
        ({ drivers: driversForWave, shiftStartTime }, i) => (
          <React.Fragment key={`drivers${i}`}>
            <div className="py-1">
              <h3 className="text-md text-secondary">
                {shiftStartTime
                  ? formatIso8601ToTime(shiftStartTime, TZ)
                  : 'No'}{' '}
                {'wave • '}
                {driversForWave.length}{' '}
                {pluralize(driversForWave.length, 'driver', 'drivers')}
              </h3>
            </div>
            <table className="mt-2 mb-12 w-full table-auto whitespace-nowrap bg-primary rounded-xl">
              <tbody>
                {driversForWave.map((d) => (
                  <DriverRow key={d.vehicleId} d={d} />
                ))}
              </tbody>
            </table>
          </React.Fragment>
        ),
      )}
    </div>
  );
};
