import cs from 'classnames';

export type Props = {
  completed: number;
  total: number;
  compact?: boolean;
  color?: 'success' | 'warning' | 'info' | 'danger';
};

export const ProgressBar = ({
  completed,
  total,
  color = 'success',
  compact = true,
}: Props) => {
  const bg =
    color === 'success'
      ? 'bg-success'
      : color === 'warning'
        ? 'bg-warning'
        : color === 'info'
          ? 'bg-info'
          : 'bg-danger';

  const progress = Math.round((completed / total) * 100);

  return (
    <div
      className={cs('relative w-full', {
        'h-1': compact,
        'h-1.5 rounded-full': !compact,
      })}
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
    >
      <div
        className={cs('absolute h-full rounded-l-full', bg, {
          'rounded-r-full': progress === 100,
        })}
        style={{
          top: 0,
          left: 0,
          width: `${progress}%`,
        }}
      />
      {progress > 5 ? (
        <p
          className={cs('absolute text-xs pb-1', {
            'text-success': color === 'success',
            'text-warning': color === 'warning',
            'text-info': color === 'info',
            'text-danger': color === 'danger',
          })}
          style={{
            left: `${progress}%`,
            transform: 'translateX(-100%) translateY(-100%)',
          }}
        >
          {progress}%
        </p>
      ) : null}
    </div>
  );
};
