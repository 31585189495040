import { useRouter } from 'next/router';
import React from 'react';
import { useCurrentUserQuery } from '../generated/graphql';
import { NextPageWithLayout } from '../pages/_app';
import { Routes, linkTo } from './routes';

// redirectIfNotLoggedIn checks if the user can be fetched. If not, and an unauthorized
// error is thrown, it redirects to the login page. It's kind of a hack
// around next.js's limitations on redirecting from the getServerSideProps function.
export function useRedirectIfNotLoggedIn(): {
  redirected?: boolean;
  firstLoad?: boolean;
} {
  const { data, loading, error } = useCurrentUserQuery();
  const router = useRouter();

  if (data?.currentUser?.user) {
    return { redirected: false };
  } else if (loading) {
    return { firstLoad: true };
  } else if (!error) {
    // If we're doing SSR and there's no user, assume we'll redirect
    // client-side as the router won't work on the server.
    if (typeof window !== 'undefined') {
      router.replace(linkTo(Routes.login, { redirect_uri: router.asPath }));
    }
    return { redirected: true };
  }

  return { redirected: false };
}

export function requireAuthentication<T>(
  // biome-ignore lint/complexity/noBannedTypes: Hangover from ESLint migration
  Cmp: React.ComponentType<T> & { getInitialProps?: Function },
) {
  const Wrapped = (props: T) => {
    const { redirected, firstLoad } = useRedirectIfNotLoggedIn();
    if (redirected || firstLoad) {
      return null;
    }
    return <Cmp {...props} />;
  };
  Wrapped.displayName = `requireAuthentication(${Cmp.displayName || Cmp.name})`;
  Wrapped.getInitialProps = Cmp.getInitialProps;
  return Wrapped;
}

// redirectIfLoggedIn checks if the user can be fetched. If not, and an unauthorized
// error is thrown, it allows the page to load, else it redirects to dashboard.
// It's kind of a hack
// around next.js's limitations on redirecting from the getServerSideProps function.
function useRedirectIfLoggedIn(): {
  redirected?: boolean;
  firstLoad?: boolean;
} {
  const { data, loading, error } = useCurrentUserQuery();
  const router = useRouter();

  if (data?.currentUser?.user) {
    // If we're doing SSR and there's a user, assume we'll redirect
    // client-side as the router won't work on the server.
    if (typeof window !== 'undefined') {
      router.replace(linkTo(Routes.home));
    }
    return { redirected: true };
  } else if (loading) {
    return { firstLoad: true };
  } else if (!error) {
    return { redirected: false };
  }

  return { redirected: false };
}

export function requireUnauthenticated(Cmp: NextPageWithLayout) {
  const Wrapped: NextPageWithLayout = (props) => {
    const { redirected } = useRedirectIfLoggedIn();
    if (redirected) {
      return null;
    }
    return <Cmp {...props} />;
  };
  Wrapped.displayName = `requireUnauthenticated(${
    Cmp.displayName || Cmp.name
  })`;
  Wrapped.getInitialProps = Cmp.getInitialProps;
  Wrapped.getLayout = Cmp.getLayout;
  return Wrapped;
}
