import { Button } from '@packfleet/ui';
import classNames from 'classnames';
import { ReactNode } from 'react';

export const ToggleButton = ({
  position,
  checked,
  onClick,
  children,
  tall = true,
  className,
}: {
  position?: 'left' | 'right' | 'middle' | undefined;
  checked?: boolean;
  onClick?: () => void;
  children?: ReactNode;
  tall?: boolean;
  className?: string;
}) => (
  <Button
    unstyled
    className={classNames(className, {
      'h-12 w-2/3': tall,
      'border-2 border-primaryDark': checked,
      'border border-primary hover:border-primaryDark px-[1px]': !checked,
      // 1px negative margin is needed so the border doesn't move the text
      'border-l-0 hover:border-l hover:pl-0': !checked && position === 'right',
      'border-r-0 hover:border-r hover:pr-0': !checked && position === 'left',
      'rounded-l-lg': position === 'left',
      'rounded-r-lg': position === 'right',
      'rounded-lg': !position,
    })}
    onClick={onClick}
  >
    <span className="text-sm">{children}</span>
  </Button>
);
