import cs from 'classnames';
import React from 'react';
import { PackUnloadingFragment } from '../../generated/graphql';
import Heading from '../heading/Heading';
import { ProgressBar } from './ProgressBar';

export type Props = {
  children?: React.ReactNode;
  unloading: PackUnloadingFragment;
};

export const UnloadingItem = ({ unloading: u, children }: Props) => {
  const done = u.packsStowed === u.packsScheduledForCollection;
  return (
    <div
      key={u.organizationId}
      className={cs('mb-4', {
        'opacity-50': done,
      })}
    >
      <div>
        <Heading level={4} className="mb-2 text-sm">
          {u.organization.name}
        </Heading>
      </div>
      <div className="flex flex-col gap-2 text-sm">
        <div className="flex items-center gap-4 text-neutral">
          <div className="w-24 shrink-0">Collected</div>
          <div className="w-60 text-right text-secondary shrink-0">
            {u.packsCollected} of {u.packsScheduledForCollection}
          </div>
          <div className="grow">
            <ProgressBar
              compact={false}
              color={
                u.packsCollected === u.packsScheduledForCollection
                  ? 'success'
                  : 'warning'
              }
              completed={u.packsCollected}
              total={u.packsScheduledForCollection}
            />
          </div>
        </div>
        <div className="flex items-start gap-4 text-neutral">
          <div className="w-24 shrink-0">Stowed</div>
          <div className="w-60 text-right text-secondary shrink-0">
            {u.packsStowed} of {u.packsCollected}
          </div>
          <div className="grow pt-2">
            <ProgressBar
              compact={false}
              color={u.packsStowed === u.packsCollected ? 'success' : 'warning'}
              completed={u.packsStowed}
              total={u.packsCollected}
            />
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnloadingItem;
