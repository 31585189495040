import Dialog from '@components/dialog/Dialog';
import Select from '@components/select/Select';
import React, { useState } from 'react';
import { useListStaffMembersQuery } from '../../generated/graphql';

export type Props = {
  currentPicker?: {
    userId: string;
    name: string;
  };
  onAssignNewPicker: (userId?: string) => Promise<{ error?: string }>;
  children?: React.ReactNode;
  disabled?: boolean;
  otherPickers: { name: string; id: string }[];
};

export const AssignPickerDialog = ({
  currentPicker,
  onAssignNewPicker,
  children,
  disabled,
  otherPickers,
}: Props) => {
  const [selectedPicker, setSelectedPicker] = useState(currentPicker);
  const { data } = useListStaffMembersQuery();
  const members = data?.listStaffMembers?.members ?? [];
  const unassigning = currentPicker && !selectedPicker;

  if (disabled) {
    return <>{children}</>;
  }

  return (
    <Dialog
      title="Assign a picker"
      description="Select a new picker for this route, or click ⨉ to unassign"
      trigger={children}
      onConfirm={async () => {
        return onAssignNewPicker(selectedPicker?.userId);
      }}
      confirmText={
        unassigning
          ? `Unassign ${currentPicker?.name}`
          : selectedPicker
            ? `Assign to ${selectedPicker.name}`
            : `Assign picker`
      }
      disabled={selectedPicker?.userId === currentPicker?.userId}
      confirmColor={unassigning ? 'danger' : 'success'}
      cancelText={'Cancel'}
    >
      {otherPickers.length ? (
        <>
          <h2 className="block text-sm font-medium text-brandDark mt-4">
            Previous Pickers
          </h2>
          <ul className="mb-4">
            {otherPickers.map((picker) => (
              <li key={picker.id}>{picker.name}</li>
            ))}
          </ul>
        </>
      ) : null}
      <Select
        isMulti={false}
        isClearable={true}
        placeholder={'No picker'}
        options={members.map((m) => {
          return {
            label: m.user.name,
            value: m.userId,
          };
        })}
        value={
          selectedPicker
            ? {
                label: selectedPicker.name,
                value: selectedPicker.userId,
              }
            : undefined
        }
        onChange={(v) => {
          setSelectedPicker(
            v
              ? {
                  userId: v.value,
                  name: v.label,
                }
              : undefined,
          );
        }}
      />
    </Dialog>
  );
};
