const andListFormatter = new Intl.ListFormat(undefined, {
  style: 'long',
  type: 'conjunction',
});

/**
 * Formats a list with commas and an 'and', e.g. ['a', 'b', 'c'] -> 'a, b and c'
 * @param  {string[]} list
 * @return {string}
 */
export const formatListWithAnd = (list: string[]) =>
  andListFormatter.format(list);

const orListFormatter = new Intl.ListFormat(undefined, {
  style: 'long',
  type: 'disjunction',
});

/**
 * Formats a list with commas and an 'or', e.g. ['a', 'b', 'c'] -> 'a, b or c'
 * @param  {string[]} list
 * @return {string}
 */
export const formatListWithOr = (list: string[]) =>
  orListFormatter.format(list);
