import Error from 'next/error';
import React from 'react';
import { useCurrentUserQuery } from '../generated/graphql';
import { NextPageWithLayout } from '../pages/_app';
import { useRedirectIfNotLoggedIn } from './require-authentication';

export function requireStaffMember(Cmp: NextPageWithLayout) {
  const Wrapped: NextPageWithLayout = (props) => {
    const { redirected, firstLoad } = useRedirectIfNotLoggedIn();
    const { data, error } = useCurrentUserQuery();
    if (firstLoad || redirected) {
      return null;
    }
    if (error) {
      return (
        <div className="flex justify-center w-full">
          <Error statusCode={500} title={error.message} />
        </div>
      );
    }
    if (!data?.currentUser?.user?.staffMember) {
      return (
        <div className="flex justify-center w-full">
          <Error statusCode={403} title="Forbidden" />
        </div>
      );
    }
    return <Cmp {...props} />;
  };

  Wrapped.displayName = `requireAdmin(${Cmp.name || Cmp.displayName})`;
  Wrapped.getInitialProps = Cmp.getInitialProps;
  Wrapped.getLayout = Cmp.getLayout;
  return Wrapped;
}
