export function downloadFile(data: any, name: string, contentType: string) {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: contentType }),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
}

export function downloadUrl(url: string) {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'true');
  document.body.appendChild(link);
  link.click();
}
